#login-paper {
    margin-top: 50px;
}

.login-signup h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #000;
    margin-top: 0;
}

.google-button {
    color: #125CC6!important;
    border-color: #125CC6!important;
    font-weight: 700!important;
    padding: 12px!important;
}

.login-submit {
    background-color: #3DCB55!important;
    font-weight: 700!important;
    padding: 12px!important;
    margin-top: 10px!important;
}

.login-bg h2 {
    color: #3067c4;
    font-weight: 300;
    font-size: 2.8vw;
    line-height: 1.4;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
}

.login-bg h2 span {
    font-weight: 900;
}

.login-bg img {
    width: 100%;
    max-width: 500px;
    margin: 30px auto 0;
    display: block;
}

.login-options {
    text-align: left;
    margin-top: 30px;
}

.login-options p {
    color: rgba(0, 0, 0, 0.60);
}

#forgot-pin {
    max-width: 600px;
    margin: 20px auto;
}

@media only screen and (max-width: 900px) {
    .login-bg {
        display: none;
    }
    
    #login-paper {
        margin-top: 10px;
    }    
}