header {
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    position: relative;
    z-index: 999;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    flex: 0 0 auto;
    width: 180px;
}

.header-menu {
    flex: 1 1 auto;
    text-align: right;
}

.logo img {
    width: 100%;
}

.header-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.header-menu li {
    display: inline-block;
    margin-left: 35px;
    list-style: none;
}

.header-menu li:first-child {
    margin-left: 0;
}

.header-menu li a {
    color: #125CC6;
    font-size: 15px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
}

.dashboard-button.MuiButtonBase-root {
    font-weight: 700;
    padding: 6px 16px;
    border-radius: 150px;
    background: #3DCB55;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #fff!important;
    border: 1px solid transparent;
}

.dashboard-button.MuiButtonBase-root:hover {
    padding: 6px 16px;
    border-radius: 150px;
    color: #3DCB55!important;
    background: #fff;
    border: 1px solid #3DCB55;
}
.header-mobile-menu {
    display: none;
}

@media (min-width: 900px) {
    #header-mobile-dialog {
        display: none;
    }
}

@media (max-width: 900px) {
    header {
        padding: 10px 0;
        position: fixed;
        top: 0;
        z-index: 9999;
    }

    .logo {
        width: 150px;
        padding-top: 5px;
    }

    #hamburger-button {
        top: 13px;
    }
    .header-menu {
        display: none;
    }

    .header-mobile-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        /* transform: translateX(-100%);
        transition: transform 0.3s ease-in-out; */
    }

    .header-mobile-menu.open {
        transform: translateX(0);
    }

    #hamburger-button {
        display: block;
        position: absolute;
        top: 13px;
        right: 1rem;
        font-size: 1.5rem;
        color: #333;
        cursor: pointer;
    }

    #hamburger-button svg {
        font-size: 32px;
    }

    #header-mobile-dialog .mobile-menu-items {
        list-style: none;
        position: fixed;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        left: auto;
        right: 0;
        top: 72px;
        right: 0;
        /* height: 100%; */
        height: 100vh;
        z-index: 999999;
        overflow-y: auto;
    }

    #header-mobile-dialog ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    #header-mobile-dialog ul, .mobile-menu-items .accordion-menus {
        width: 250px;
    }

    .mobile-menu-items li {
        padding: 10px;
    }

    .mobile-menu-items li svg {
        margin-right: 15px;
        vertical-align: text-top;
    }

    .dashboard-button.MuiButtonBase-root {
        border-radius: 6px;
        padding: 6px 30px;
        width: 100%;
    }
    
    .mobile-menu-items .accordion-menus {
        bottom: 20px;
        box-shadow: none;
    }
    
    .mobile-menu-items .accordion-menus .MuiAccordion-root {
        padding: 6px 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 0;
        box-shadow: none;
    }

    .accordion-menus .MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px;
    }
    
    .mobile-menu-items .accordion-menus .MuiAccordion-root:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    }

    .mobile-menu-items .MuiAccordionSummary-root {
        padding: 0;
    }

    .mobile-menu-items .MuiAccordionSummary-content, 
    .mobile-menu-items .MuiAccordionSummary-content.Mui-expanded {
        margin: 5px 0;
    }

    .mobile-menu-items .MuiAccordionSummary-content p {
        margin: 0;
    }

    .mobile-menu-items .MuiAccordionDetails-root {
        padding: 0 10px;
    }
}

@media (max-width: 600px) {
    header {
        margin-bottom: 10px;
    }
}
