
footer {
    background: #fff;
    color: #98A2B0;
    padding: 30px 0;
}

footer.mini-footer p {
    margin: 0;
}

footer a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 10px;
}

footer ul:last-child {
    margin-bottom: 0;
}

footer ul li {
    margin-bottom: 12px;
}

footer .footer-nav-title {
    display: block;
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

footer .wrapper {
    display: flex;
}

.copyright, .footer-nav {
    display: flex;
}

.footer-nav {
    flex-grow: 1;
    max-width: 780px;
    margin-left: auto;
    justify-content: space-between;
}

.copyright {
    margin-right: 25px;
    flex-direction: column;
}

.copyright p {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}

.footer-logo {
    display: block;
    height: 53px;
    overflow: hidden;
    width: 53px;
    margin: 0 auto;
    text-indent: -1000px;
    /* background: url("../images/footer-logo.png") center center no-repeat; */
    background-size: 53px 53px;
    opacity: 0.5;
    transition: all 0.25s ease;
}

.footer-logo:hover {
    opacity: 1;
}

.footer-date {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
}


@media only screen and (max-width: 800px) {
    .copyright, .footer-nav {
        display: block;
    }
    
    .copyright {
        text-align: center;
        margin-right: 0;
    }

    .footer-nav {
        margin-bottom: 30px;
    }

    footer .wrapper {
        flex-direction: column-reverse;
    }

    footer ul {
        display: block;
        margin: 0 0 1.5em 0px;
        width: 100%;
        text-align: center;
    }

    .footer-nav {
        max-width: none;
        margin-left: 0;
    }

    footer .footer-nav-title {
        text-align: center;
    }

    footer ul li {
        display: inline-block;
    }

    footer a {
        display: inline;
        margin: 0 10px;
    }

    .copyright a {
        display: none;
    }
}