* {
	font-family: 'Noto Sans' !important;
}

p {
	margin-top: 0;
	color: rgba(0, 0, 0, 0.87);
}

a {
	color: #125CC6;
	text-decoration: none;
}

button {
	font-weight: 700 !important;
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	width: 96%;
}

.content {
	padding-bottom: 50px;
	background-image: url("/src/assets/images/background/dashboard-bg.webp");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom;
	min-height: calc(100vh - 400px);
}

.App .MuiPaper-root {
	padding: 10px 20px;
}

.standard-padding {
	padding: 10px 20px;
}

.desktop {
	display: block;
}

.mobile {
	display: none;
}

.loader {
	padding: 30px 0;
	justify-content: center;
}

.ButtonLoader {
	justify-content: center;
}

.dashboard h1 {
	font-size: 28px;
	font-weight: 700;
	text-align: left;
	margin-top: 0;
	color: #125CC6;
}

.navigation {
	padding: 20px 0;
	text-align: left;
	display: inline-flex;
}

.navigation a {
	background-color: transparent;
	color: rgba(0,0,0, 0.6);
	border-radius: 150px;
	padding: 12px 18px;
	text-decoration: none;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	margin-right: 20px;
	display: flex;
	text-align: center;
	align-items: center;
	position: relative;
}

.navigation a.active {
	background-color: rgba(0, 0, 0, 0.08);
	color: rgba(18, 92, 198, 1);
	opacity: 1;
}

.MobileNavigation.MuiBottomNavigation-root {
	position: fixed;
	bottom: 0;
	width: 100%;
	display: none;
	z-index: 9999;
	box-shadow: 0px 4px 6px 2px #000;
	text-align: center;
	border-bottom: 4px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 5px;
}

.MuiBottomNavigationAction-label.Mui-selected {
	font-size: 12px !important;
}

.actionRow {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 12px;
	gap: 8px;
}

.flexGrow {
	flex-grow: 1;
	position: relative;
}

.marginLeft {
	margin-left: 15px !important;
}

.formDivider {
	display: flex;
	align-items: center;
}

#checkout .formDivider {
	margin: 20px 0;
}

.formDivider::before,
.formDivider::after {
	flex: 1;
	content: '';
	padding: 1px;
	background-color: #ddd;
	margin: 5px;
}

.add-more-credits.MuiButtonBase-root {
	border-radius: 6px;
	color: #125CC6;
	background-color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	padding: 10px;
	box-shadow: none;
	margin-left: auto;
}

.add-more-credits.MuiButtonBase-root:hover {
	border: 1px solid #125CC6;
	color: #125CC6;
	background-color: #fff;
}

.CreditsPaper {
	display: flex;
	align-items: center;
	min-height: 46px;
}

.CreditsPaper span {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.CreditsPaper a {
	float: right;
}

.signup-login-switch {
	text-decoration: none;
	margin-left: 10px;
}

svg[data-testid="FiberManualRecordIcon"],
svg[data-testid="VoicemailIcon"] {
	fill: rgba(0, 0, 0, 0.56);
}

.feature-button.active {
	background-color: rgba(0, 0, 0, 0.08) !important;
}

.feature-button.active svg[data-testid="FiberManualRecordIcon"] {
	fill: #ff0000;
}


.feature-button.active svg[data-testid="VoicemailIcon"] {
	fill: #3DCB55;
}

.MuiDialog-container .MuiPaper-root {
	border-radius: 12px;
}

.MuiDialog-container .MuiDialogTitle-root {
	color: rgba(0, 0, 0, 0.87);
	font-size: 24px;
	font-weight: 700;
	line-height: 133.4%;
	margin-right: 25px;
}

.close-button,
.MuiIconButton-root.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
}

.green-button {
	border-radius: 6px;
	background: #3DCB55 !important;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.MuiPopper-root {
	z-index: 1200 !important;
}

.MuiDialog-root .MuiDialogActions-root {
	padding: 0 24px 24px 24px;
}

#PurchaseDialog .MuiPaper-root[role=dialog] {
	width: 100%;
	max-width: 650px;
}

#SelectPackage .package {
	border-radius: 6px;
	border: 1px solid rgba(18, 92, 198, 0.5);
}

#SelectPackage .package button {
	margin-top: 20px;
	width: calc(100% - 20px);
	font-weight: 700;
}

.resend-code {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.resend-code button {
	padding: 0;
}

.verification-container {
	width: 100%;
	margin: 30px 0 10px;
}

.verification-container> {
	width: 100%;
	margin: 30px 0 10px;
}

.verification-character {
	background: #fff;
	border-radius: 6px;
	border: 1px solid lightgray;
}

.resend-buttons {
	width: 100%;
	text-align: left;
	display: flex;
	margin-bottom: 3px;
}

.resend-buttons button {
	padding: 0;
}

.resend-buttons button:last-child {
	margin-left: auto;
}

.resend-timer {
	text-align: right;
	font-size: 14px;
}

.box-padding {
	padding: 25px;
}

.PhoneInput {
	flex-grow: 1;
}

.PhoneInputInput {
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	padding: 16.5px 14px;
	color: rgba(0, 0, 0, 0.87);
	font-size: 16px;
}

.PhoneInputCountry {
	min-width: 50px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	padding: 16.5px 7px;
	justify-content: center;
}

.PhoneInputCountryIcon {
	width: 33px;
	height: 22px;
	box-shadow: 0px 0px 2px #a9a9a9;
}

.PhoneInputCountrySelectArrow {
	margin-left: 10px;
}

.pagination ul {
	justify-content: center;
	margin: 20px 0 10px;
}

.MuiDialogContent-root p {
	line-height: 24px;
}

.verification h2 {
	font-size: 20px;
	margin: 0 0 20px;
}

.verification-button-group {
	text-align: right;
}

.verification-button-group {
	text-align: right;
}

.verification-button-group button:first-child {
	margin-right: 5px;
}

.sale-packages {
	width: 100%;
}

.sale-packages p {
	text-align: center;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.87);
	font-size: 16px;
	line-height: 130%;
	margin: 0;
	/* min-height: 45px; */
}

p.package-amount {
	color: rgba(18, 92, 198, 1);
	font-size: 24px;
	line-height: 133.4%;
	/* margin-bottom: 20px; */
	margin-bottom: 0;
}

.sale-packages .product_image {
	width: 70px;
	height: 70px;
}

.sale-packages p.product_description {
	line-height: 100%;
	margin: 20px 0;
}

.sale-packages .corporate-package p.product_description {
	min-height: 35px;
}

.sale-packages .package {
	border: 1px solid rgba(18, 92, 198, 0.50);
	border-right: none;
	width: 100%;
	padding: 10px 0;
	text-align: center;
}

.sale-packages .package:first-of-type {
	border-radius: 6px 0 0 6px;
}

.sale-packages .package:last-of-type {
	border-radius: 0 6px 6px 0;
	border-right: 1px solid rgba(18, 92, 198, 0.50);
}

.sale-packages .previous-credits {
	text-decoration: line-through;
	color: rgba(0, 0, 0, 0.38);
	margin-bottom: 5px;
}

#SelectPackage .mobile .package {
	display: none;
}

.centerFlex {
	justify-content: center;
}

#payment-method-group {
	margin-bottom: 20px;
}

#payment-method-group button {
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	margin-right: 12px;
	text-transform: capitalize;
	flex-direction: column;
	padding: 6px 12px;
}

#payment-method-group button:last-of-type {
	margin-right: 0;
}

#payment-method-group button.Mui-selected {
	border: 2px solid rgba(5, 112, 222, 1);
	color: rgba(5, 112, 222, 1);
	background-color: transparent;
}

#payment-method-group button.Mui-selected p {
	color: rgba(5, 112, 222, 1);
}

#payment-method-group button svg,
#payment-method-group button img {
	width: 20px;
	height: 20px;
}

#payment-method-group button p {
	margin-bottom: 0;
	font-size: 16px;
}

#payment-method-group button>* {
	margin-right: auto;
}

#purchase-auto-replenish {
    margin: 30px 0 20px;
	padding-top: 10px;
    border-top: 1px solid rgba(26, 26, 26, 0.1);
}

.update-auto-replenish-saved, .update-auto-replenish-text {
    color: #0288d1;
    font-size: 14px;
    margin-left: 10px;
}

#purchase-auto-replenish .MuiCheckbox-root {
	margin-top: 8px;
	padding-left: 0;
}

#purchase-auto-replenish span.auto-replenish-span {
	vertical-align: sub;
}

#purchase-auto-replenish .submit-auto-replenish {
	display: block;
}

#purchase-auto-replenish-select {
	margin-right: 10px;
}

#purchase-summary {
	background-color: rgba(237, 247, 237, 1);
	padding: 24px;
	border-radius: 6px;
	justify-content: space-between;
	color: rgba(61, 203, 85, 1);
	margin: 10px 0 20px;
}

#purchase-summary p {
	margin-bottom: 6px;
}

#purchase-summary p,
#purchase-summary span {
	color: rgba(61, 203, 85, 1);
}

#purchase-summary span {
	font-size: 24px;
	font-weight: 700;
}

#purchase-summary #previous-credits-summary {
	text-decoration: line-through;
	font-size: 14px;
}

#purchase-summary #price-summary {
	float: right;
}

.continue-to-paypal {
	color: #fff;
	background-color: rgba(61, 203, 85, 1)!important;
}

.continue-to-paypal img {
	height: 16px;
	margin-left: 4px;
}

.continue-to-paypal span {
	font-style: italic;
	font-weight: 900;
}

#pay-with-paypal {
	padding: 15px;
    width: 100%;
    font-size: 16px;
	background-color: rgba(223, 227, 231, 1);
	color: rgba(0, 0, 0, 0.87);
}

#pay-with-paypal img {
	margin-left: 6px;
	height: 18px;
}

#pay-with-paypal p {
	margin: 0;
}

#paypal-buttons {
	display: flex; 
	justify-content: space-between;
}

.paypal-text {
	font-size: 14px;
	color: rgba(5, 112, 222, 1);
	font-weight: 600;
	max-width: calc(100% - 30px);
}

.paypal-text img {
	vertical-align: sub;
	margin-right: 10px;
}

.anyIdPaper h4, .anyIdPaperMobile h4 {
	font-size: 20px;
	margin: 10px 0 15px;
}

.anyIdPaper, .anyIdPaper p {
	font-size: 14px;
	line-height: 20px;
}

.tour-heading {
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
}

.tour-body {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

#TutorialDialog button.MuiButtonBase-root {
	padding: 10px;
	font-size: 16px;
}

.acceptTourButton.MuiButtonBase-root,
.acceptTourButton.MuiButtonBase-root:hover {
	background-color: rgba(61, 203, 85, 1);
	margin-top: 20px;
}

.overflowHidden {
	overflow: hidden;
}

.reactour__popover {
	border-radius: 8px;
}

#page-not-found {
	margin-top: 50px;
	text-align: center;
}

#page-not-found h1 {
	font-size: 48px;
	margin: 0 20px 20px;
}

#page-not-found p {
	font-size: 24px;
}

#page-not-found .MuiPaper-root {
	padding: 25px;
	max-width: 640px;
	margin: 0 auto;
}

#page-not-found button {
	font-size: 18px;
}

.value-badge {
    background: #FB7E21;
    padding: 0;
    line-height: 21px;
    width: 22px;
    height: 22px;
    display: inline-block;
    border-radius: 15px;
    text-shadow: none;
    font-size: 10px;
    position: absolute;
    color: #fff;
    top: -4px;
    right: -4px;
}

.new-feature-badge {
	color: #fff;
    background-color: rgba(61, 203, 85, 1);
    margin-left: 10px;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 10px;
    font-weight: 200;
}

.mobile-new-feature-badge:after {
	content: "NEW!";
    position: absolute;
    font-size: 7px;
    color: #fff;
    background-color: rgba(61, 203, 85, 1);
    padding: 1px 4px;
    border-radius: 10px;
    font-weight: 200;
    bottom: -4px;
    left: auto;
    right: auto;
}

button.text-button {
    text-transform: inherit!important;
    padding: 0!important;
    font-size: 16px;
    vertical-align: baseline!important;
}

#revert-site-alert, .site-alert {
    margin-bottom: 10px;
}

#revert-site-alert p, .site-alert p {
    margin-bottom: 0;
}

#AutoReplenishDialog .MuiDialog-paper {
	width: 100%;
    max-width: 725px;
}

#AutoReplenishDialog img {
	display: flex;
    align-items: center;
	width: 100%;
}

#AutoReplenishDialog img.mobile-img {
	display: none;
}

#AutoReplenishDialog h2 {
    text-align: center;
}

#AutoReplenishDialog #enable-auto-replenish-button {
	padding: 10px 5px;
    font-size: 16px;
	margin-top: 10px;
	background-color: #3DCB55;
}

.CreditsPaper.MuiPaper-root {
	padding: 10px;
	margin-bottom: 15px;
}

#sidebarImage img, #sidebarImageMobile img {
	border-radius: 4px;
	box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);
}

#sidebarImageMobile, .anyIdPaperMobile {
	display: none;
}

#select-packages-promotional {
	border-radius: 6px;
	width: 100%;
	margin-bottom: 5px;
}

#PhonePad {
	display: grid;
	padding: 10px;
	width: 160px;
}

.keypad {
	display: grid;
    grid-template-columns: auto auto auto;
	height: 100%;
	column-gap: 0;
	/* column-gap: 15px; */
    row-gap: 8px;
}

.keypad button {
	background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px;
    text-align: center;
    border-radius: 100%;
    font-weight: 400!important;
	font-size: 16px;
	width: 45px;
	height: 45px;
	margin: auto;
}

#PhonePad input {
	border: none;
	font-size: 18px;
	margin-bottom: 15px;
	text-align: center;
	width: 100%;
}

.auto-replenish-select {
    margin-bottom: 20px;
}

.auto-replenish-switch {
    margin-left: auto;
}

.auto-replenish-heading {
	color: var(--text-primary, rgba(0, 0, 0, .87));
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .15px;
    line-height: 160%;
    margin-top: 0;
}

.link-button {
	text-decoration: underline!important;
}

#banner_alert .alert-link {
	float: right;
	text-decoration: underline;
}

@media only screen and (max-width: 900px) {
	.navigation {
		padding: 10px 0;
	}

	.navigation a {
		font-size: 14px;
		padding: 8px 12px;
		margin-right: 5px;
	}

	.content {
		padding-top: 80px;
	}

	.MuiDialog-container {
		margin-top: 70px;
		/* max-height: calc(100vh - 125px); */
		max-height: calc(100vh - 175px);
	}

	.gridMobileMargin.MuiGrid-item {
		margin-bottom: 30px;
	}

	#sidebarImage, .anyIdPaper {
		display: none;
	}

	#sidebarImageMobile, .anyIdPaperMobile {
		display: block;
		margin-top: 20px;
	}

	.new-feature-badge {
		margin-left: 4px;
		padding: 0 4px;
		font-size: 10px;
	}

	.value-badge {
		line-height: 15px;
		width: 16px;
		height: 16px;
		font-size: 10px;
		top: -5px;
		right: 3px;
	}
}

@media only screen and (max-width: 600px) {
	.App {
		padding-top: 0;
		padding-bottom: 50px;
	}

	.App .MuiPaper-root {
		padding: 10px 10px;
	}

	.MobileNavigation.MuiBottomNavigation-root {
		display: flex;
	}

	.navigation {
		display: none;
	}

	#SelectPackage .package {
		border-radius: 6px;
		border: 1px solid rgba(18, 92, 198, 0.5);
	}

	#SelectPackage .package button {
		margin-top: 20px;
		width: calc(100% - 20px);
		font-weight: 700;
	}

	.box-padding {
		padding: 10px !important;
	}

	.MuiTextField-root .MuiFormLabel-root.MuiInputLabel-root {
		font-size: 14px;
	}

	.MuiTextField-root .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
		font-size: 1rem;
	}

	.desktop {
		display: none !important;
	}

	.mobile {
		display: block;
	}

	.sale-packages .package {
		width: auto;
	}

	#SelectPackage .mobile .package {
		display: flex;
		padding: 8px;
		margin: 0 0 15px 0;
		align-items: center;
	}

	#SelectPackage .desktop .package {
		display: none;
	}

	.sale-packages .previous-credits {
		margin-bottom: 0;
		margin: 0 2px;
	}

	#SelectPackage .mobile .package button {
		width: 160px;
        margin-top: 0;
        margin-left: auto;
        padding: 8px 8px;
        font-size: 16px;
	}

	.sale-packages.mobile .has-previous-credits p {
		width: calc(50% - 75px);
		text-align: left;
	}

	#SelectPackage .sale-packages p {
		font-size: 20px;
	}

	#SelectPackage .MuiPaper-root {
		margin: 32px 16px;
	}

	.sale-packages .product_image {
		display: none;
	}

	#payment-method-group button {
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		margin-right: 12px;
		text-transform: capitalize;
		flex-direction: column;
		padding: 3px 6px;
	}

	#payment-method-group button svg,
	#payment-method-group button img {
		width: 16px;
		height: 16px;
	}

	#payment-method-group button p {
		margin-bottom: 0;
		font-size: 14px;
	}

	#CompletePurchase .MuiInputBase-root {
		font-size: 14px;
	}

	#purchase-auto-replenish-select {
		margin-right: 0;
		width: calc(100% - 51px);
	}

	#purchase-auto-replenish span.auto-replenish-span {
		display: block;
		margin-left: 50px;
	}

	.purchase-auto-replenish-select {
		margin-bottom: 10px;
	}

	#apply-coupon-button {
		font-size: 13px;
	}

	.MuiDialog-root {
		/* height: calc(100% - 56px); */
	}

	.tour-heading {
		font-size: 16px;
		line-height: 16px;
		margin-bottom: 10px;
	}

	.tour-body {
		font-size: 12px;
		line-height: 18px;
	}

	.reactour__helper {
		padding: 15px;
	}

	div[data-tour-elem=controls] {
		margin-top: 16px;
	}

	.value-badge {
		right: 26%;
		top: 0;
		line-height: 16px;
		width: 16px;
		height: 16px;
		font-size: 8px;
	}

	#AutoReplenishDialog img, #AutoReplenishDialog img.mobile-img {
		margin: 0 auto;
		display: block;
		width: 300px;
        max-width: 100%;
		border-radius: 6px;
	}

	#AutoReplenishDialog p {
		font-size: 13px;
		line-height: 21px;
		margin-bottom: 16px;
	}

	#AutoReplenishDialog #enable-auto-replenish-button {
		padding: 6px 3px;
		font-size: 14px;
		margin-top: 6px;
	}

	#AutoReplenishDialog .MuiSelect-select {
		padding: 12px 14px;
	}

	#purchase-summary {
		padding: 12px;
	}

	#purchase-summary span {
		font-size: 18px;
	}

	#paypal-buttons {
		display: block; 
	}

	#paypal-buttons button {
		width: 100%;
	}

	#paypal-buttons button:first-of-type {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 450px) {
	a.MuiBottomNavigationAction-root {
		padding: 0 2px;
		min-width: 0;
	}

	a.MuiBottomNavigationAction-root svg {
		width: 16px;
		height: 16px;
	}

	a.MuiBottomNavigationAction-root span.MuiBottomNavigationAction-label {
		font-size: 11px;
        line-height: 11px;
	}

	.MuiDialog-container .MuiDialogTitle-root,
	.MuiDialog-container .MuiDialogContent-root {
		padding: 16px;
	}

	.MuiDialog-container .MuiDialogTitle-root {
		font-size: 18px;
	}

	.MuiDialog-container .MuiDialogContent-root {
		font-size: 14px;
	}

	.MuiDialog-root .MuiDialogActions-root {
		padding: 0 16px 16px 16px;
	}

	.MuiDialog-container>.MuiPaper-root {
		margin: 16px;
	}

	.verification-input {
		height: 55px;
	}

	#countryLabel {
		font-size: 14px;
		top: 2px;
		left: -2px;
	}

	.actionRow {
		gap: 4px;
	}

	.mobileFlexInput {
		width: calc(100% - 75px);
	}

	.verification-method-radio-group .MuiFormControlLabel-root span {
		font-size: 14px;
	}

	#SelectPackage .sale-packages p {
		font-size: 16px;
		margin-right: 10px;
	}

	#SelectPackage .mobile .package button {
		width: 140px;
		font-size: 14px;
		padding: 10px 4px;
	}

	#SelectPackage .mobile .package {
        padding: 4px 6px;
        margin: 0 0 8px 0;
    }

	#SelectPackage .sale-packages.mobile .has-previous-credits p {
		width: calc(50% - 50px);
	}

	#SelectPackage .sale-packages .previous-credits {
		font-size: 12px;
		text-align: center;
	}

	.CreditCardImages {
		max-width: 80px;
	}

	.reactour__popover {
		max-width: 350px !important;
		padding: 18px 22px !important;
		left: 20px !important;
	}

	#page-not-found h1 {
		font-size: 32px;
		margin: 0 15px 15px;
	}

	#page-not-found p {
		font-size: 16px;
	}

	#page-not-found .MuiPaper-root {
		padding: 15px;
		max-width: 98%;
	}

	#page-not-found button {
		font-size: 14px;
	}

	.value-badge {
		top: 2px;
	}
}

@media only screen and (max-width: 350px) {
	#countryLabel {
		font-size: 12px;
		top: 3px;
	}

	.mobileFlexInput {
		width: calc(100% - 65px);
	}

	#SelectPackage {
		padding: 10px;
	}
}